import update from 'immutability-helper';
import MultiSort from 'multi-sort';
import {
    MENU_SET_INFO,
    PRODUCT_SET_DEFAULT,
    PRODUCT_REMOVE_OPTION_VALUE,
    PRODUCT_SET_OPTION_UNIQUE_CHOICE,
    PRODUCT_SET_OPTION_UNIQUE_EXTRA,
    PRODUCT_SET_OPTION_UNIQUE_EXTRA_PLUS,
    PRODUCT_SET_OPTION_MULTIPLE_EXTRA,
    PRODUCT_SET_OPTION_MULTIPLE_EXTRA_PLUS,
    PRODUCT_SET_BASE_GROUP_OPTION,
    PRODUCT_SET_OPTION_UNIQUE_CHOICE_FOR_A_GROUP_OPTION,
    PRODUCT_SET_OPTION_UNIQUE_EXTRA_FOR_A_GROUP_OPTION,
    PRODUCT_SET_OPTION_UNIQUE_EXTRA_PLUS_FOR_A_GROUP_OPTION,
    PRODUCT_SET_OPTION_MULTIPLE_EXTRA_FOR_A_GROUP_OPTION,
    PRODUCT_SET_OPTION_MULTIPLE_EXTRA_PLUS_FOR_A_GROUP_OPTION,
    PRODUCT_SET_OPTION_REPLACE,
    BUILD_COMPOSITIONS,
    COMPOSITION_REMOVE_ONE,
    COMPOSITION_ADD_ONE,
    SET_COMPOSITION_QUANTITY,
    CHANGE_SLICE_PRICE,
    HANDLE_REMOVAL_INGREDIENTS,
    FORMULA_HANDLE_REMOVAL_INGREDIENTS,
    RESET_CART,
    CART_PRODUCT_ADD,
    CART_PRODUCT_ADD_ONE,
    CART_PRODUCT_UPDATE_QUANTITY,
    CART_PRODUCT_REMOVE_BY_PRODUCT_ID,
    CART_PRODUCT_REMOVE,
    CART_PRODUCT_REMOVE_ONE,
    SET_SCHEDULE,
    SET_CART_USER_INFO,
    FORMULA_CURRENT,
    FORMULA_ITEM_REMOVE,
    FORMULA_ITEM_ADD,
    CONFIRM_FORMULA,
    DELETE_CART_FORMULA,
    REMOVE_ONE_QUANTITY_TO_FORMULA,
    ADD_ONE_QUANTITY_TO_FORMULA,
    SET_SELECTED_REGIMES,
    SET_CURRENCY,
    SET_BROWSER_LANG,
    ORDER_SENDING,
    BOOKING_SENDING,
    CART_SET_COMMENT,
    CART_SET_CUSTOMER_FIRSTNAME,
    COMPOSITION_SET_COMMENT,
    CART_SET_NB_PEOPLE,
    SET_VENUE,
    COMPOSITION_RANDOM,
    SET_ELIOR_MENU_TYPE,
    PRODUCT_FORMULA_SET_OPTION_UNIQUE_CHOICE,
    PRODUCT_FORMULA_SET_OPTION_UNIQUE_EXTRA,
    PRODUCT_FORMULA_SET_OPTION_UNIQUE_EXTRA_PLUS,
    PRODUCT_FORMULA_SET_OPTION_MULTIPLE_EXTRA,
    PRODUCT_FORMULA_SET_OPTION_MULTIPLE_EXTRA_PLUS,
    COMPOSITION_FORMULA_ADD_OPTION,
    COMPOSITION_FORMULA_REMOVE_OPTION,
    REDUCED_REGIME,
    PRODUCT_DISCOUNT_ADD,
    PRODUCT_DISCOUNT_REMOVE,
    SET_SELECTED_MENU,
    SET_MENU_TYPE,
} from './constants';

import isEqual from '~/src/lib/common/utils/isEqual';

import parseLanguages from '~/src/lib/parseLanguages';

function getFormulasUuid(formulas) {
    const data = {};
    formulas.forEach((formula) => {
        data[formula.uuid] = [];
    });
    return data;
}

const cart = {
    products: [],
    user: {},
    schedule: {},
    comment: '',
    nbPeople: 2,
    venue: '',
    qrcode: false,
    customer_firstname: '',
};

// The initial state of the App
export const initialState = {
    formulaBuilding: {}, // construction des formules
    formulaCurrentUuid: '', // formule courante
    cart: cart,
    //cart: [],
    //cartFormula: [],
    composition: {},
    compositions: [],
    selectedRegimes: [],
    filter: [],
    menuType: 'inside',
    currency: false,
    lang: 'fr',
    order: {},
    orderSending: false,
    bookingSending: false,
    reducedRegime: false,

    selectedMenu: false,
    menuType: 'inside',
};

export default function commonReducer(state = initialState, action) {
    //export default (state = initialState, action) => {
    switch (action.type) {
        case BUILD_COMPOSITIONS: {
            return { ...state, compositions: action.payload };
        }

        case COMPOSITION_RANDOM: {
            return update(state, {
                composition: {
                    $merge: {
                        random: Math.floor(Math.random() * Math.floor(1000)),
                    },
                },
            });
        }

        case COMPOSITION_REMOVE_ONE: {
            let quantity = state.composition.quantity - 1;
            if (quantity <= 0) {
                quantity = 1;
            }

            return update(state, {
                composition: { $merge: { quantity: quantity } },
            });
        }

        case COMPOSITION_SET_COMMENT: {
            return update(state, {
                composition: { $merge: { comment: action.payload } },
            });
        }

        case COMPOSITION_ADD_ONE: {
            let quantity = state.composition.quantity + 1;

            return update(state, {
                composition: { $merge: { quantity: quantity } },
            });
        }

        case SET_COMPOSITION_QUANTITY: {
            return update(state, {
                composition: { $merge: { quantity: action.payload } },
            });
        }

        case FORMULA_CURRENT: {
            return { ...state, formulaCurrentUuid: action.payload };
        }

        case FORMULA_ITEM_REMOVE: {
            return update(state, {
                formulaBuilding: {
                    [state.formulaCurrentUuid]: {
                        $splice: [[action.payload.index, 1]],
                    },
                },
            });
        }

        case FORMULA_ITEM_ADD: {
            return update(state, {
                formulaBuilding: {
                    [state.formulaCurrentUuid]: { $push: [action.payload] },
                },
            });
        }

        case CONFIRM_FORMULA: {
            const formula = {
                uuid: action.payload,
                uniqueUuid: Date.now(),
                quantity: 1,
                products: state.app.formulaBuilding[action.payload],
            };

            return update(state, {
                cartFormula: { $push: [formula] },
                formulaBuilding: { [action.payload]: { $set: [] } },
            });
        }

        case DELETE_CART_FORMULA: {
            const index = state.cartFormula.findIndex(
                (item) => item.uniqueUuid === action.payload,
            );

            if (index !== -1) {
                return update(state, {
                    cartFormula: { $splice: [[index, 1]] },
                });
            }

            return state;
        }

        case REMOVE_ONE_QUANTITY_TO_FORMULA: {
            const index = state.cartFormula.findIndex(
                (item) => item.uniqueUuid === action.payload,
            );
            if (index !== -1) {
                let quantity = state.cartFormula[index].quantity - 1;
                return update(state, {
                    cartFormula: {
                        [index]: { $merge: { quantity: quantity } },
                    },
                });
            }
            return state;
        }

        case ADD_ONE_QUANTITY_TO_FORMULA: {
            const index = state.cartFormula.findIndex(
                (item) => item.uniqueUuid === action.payload,
            );

            if (index !== -1) {
                let quantity = state.cartFormula[index].quantity + 1;
                return update(state, {
                    cartFormula: {
                        [index]: { $merge: { quantity: quantity } },
                    },
                });
            }

            return state;
        }

        case CHANGE_SLICE_PRICE: {
            return update(state, {
                composition: { cartPrice: { $set: action.payload } },
            });
        }

        case HANDLE_REMOVAL_INGREDIENTS: {
            const index = state.composition.removalIngredients.findIndex(
                (id) => {
                    return id === action.payload;
                },
            );
            if (index !== -1) {
                return update(state, {
                    composition: {
                        removalIngredients: { $splice: [[index, 1]] },
                    },
                });
            } else {
                return update(state, {
                    composition: {
                        removalIngredients: { $push: [action.payload] },
                    },
                });
            }
        }

        case PRODUCT_SET_DEFAULT: {
            return update(state, { composition: { $set: action.payload } });
        }
        case SET_SELECTED_REGIMES: {
            return { ...state, selectedRegimes: action.payload };
        }

        case PRODUCT_SET_BASE_GROUP_OPTION: {
            const index = state.composition.properties.findIndex((item) => {
                return (
                    item.optionId === action.payload.optionGroupId &&
                    item.type === 'options'
                );
            });
            if (index === -1) {
                return update(state, {
                    composition: {
                        properties: { $push: [action.payload] },
                    },
                });
            }

            return state;
        }

        case PRODUCT_REMOVE_OPTION_VALUE: {
            const index = state.composition.properties.findIndex((item) => {
                return (
                    item.optionId === action.payload.optionId &&
                    item.optionValueId === action.payload.optionValueId
                );
            });

            if (index !== -1) {
                return update(state, {
                    composition: {
                        properties: { $splice: [[index, 1]] },
                    },
                });
            }

            return state;
        }

        /*  DISCOUNT    */
        case PRODUCT_DISCOUNT_ADD: {
            const index = state.composition.discounts.findIndex(
                (item) => item === action.payload,
            );

            if (index !== -1) {
                return state;
            } else {
                return update(state, {
                    composition: {
                        discounts: { $push: [action.payload] },
                    },
                });
            }
        }

        case PRODUCT_DISCOUNT_REMOVE: {
            const index = state.composition.discounts.findIndex(
                (item) => item === action.payload,
            );

            if (index !== -1) {
                return update(state, {
                    composition: {
                        discounts: { $splice: [[index, 1]] },
                    },
                });
            } else {
                return state;
            }
        }

        /*  UNIQUE CHOICE   */
        case PRODUCT_SET_OPTION_UNIQUE_CHOICE: {
            const index = state.composition.properties.findIndex((item) => {
                return (
                    item.optionId === action.payload.optionId &&
                    item.type === 'options'
                );
            });

            if (index !== -1) {
                return update(state, {
                    composition: {
                        properties: {
                            $splice: [[index, 1]],
                            $push: [action.payload],
                        },
                    },
                });
            } else {
                return update(state, {
                    composition: {
                        properties: { $push: [action.payload] },
                    },
                });
            }
        }

        case PRODUCT_SET_OPTION_UNIQUE_CHOICE_FOR_A_GROUP_OPTION: {
            const index = state.composition.properties.findIndex((item) => {
                return (
                    item.optionGroupId === action.payload.optionGroupId &&
                    item.type === 'options'
                );
            });

            if (index !== -1) {
                const index1 = state.composition.properties[
                    index
                ].options.findIndex((item) => {
                    return (
                        item.optionId === action.payload.optionId &&
                        item.type === 'options'
                    );
                });

                if (index1 !== -1) {
                    return update(state, {
                        composition: {
                            properties: {
                                [index]: {
                                    options: {
                                        $splice: [[index1, 1]],
                                        $push: [action.payload],
                                    },
                                },
                            },
                        },
                    });
                } else {
                    return update(state, {
                        composition: {
                            properties: {
                                [index]: {
                                    options: { $push: [action.payload] },
                                },
                            },
                        },
                    });
                }
            }

            return state;
        }

        /*  UNIQUE EXTRA   */

        case PRODUCT_SET_OPTION_UNIQUE_EXTRA: {
            const index = state.composition.properties.findIndex((item) => {
                return (
                    item.optionId === action.payload.optionId &&
                    item.type === 'options'
                );
            });

            if (index !== -1) {
                return update(state, {
                    composition: {
                        properties: { $splice: [[index, 1]] },
                    },
                });
            } else {
                return update(state, {
                    composition: {
                        properties: { $push: [action.payload] },
                    },
                });
            }
        }

        case PRODUCT_SET_OPTION_UNIQUE_EXTRA_FOR_A_GROUP_OPTION: {
            const index = state.composition.properties.findIndex((item) => {
                return (
                    item.optionGroupId === action.payload.optionGroupId &&
                    item.type === 'options'
                );
            });

            if (index !== -1) {
                const index1 = state.composition.properties[
                    index
                ].options.findIndex((item) => {
                    return (
                        item.optionId === action.payload.optionId &&
                        item.type === 'options'
                    );
                });

                if (index1 !== -1) {
                    return update(state, {
                        composition: {
                            properties: {
                                [index]: {
                                    options: { $splice: [[index1, 1]] },
                                },
                            },
                        },
                    });
                } else {
                    return update(state, {
                        composition: {
                            properties: {
                                [index]: {
                                    options: { $push: [action.payload] },
                                },
                            },
                        },
                    });
                }
            }

            return state;
        }

        /*  UNIQUE EXTRA PLUS  */

        case PRODUCT_SET_OPTION_UNIQUE_EXTRA_PLUS: {
            const index = state.composition.properties.findIndex((item) => {
                return (
                    item.optionId === action.payload.optionId &&
                    item.type === 'options'
                );
            });
            if (index !== -1) {
                if (action.payload.nb > 0) {
                    return update(state, {
                        composition: {
                            properties: {
                                $splice: [[index, 1]],
                                $push: [action.payload],
                            },
                        },
                    });
                }
                return update(state, {
                    composition: {
                        properties: { $splice: [[index, 1]] },
                    },
                });
            } else {
                return update(state, {
                    composition: { properties: { $push: [action.payload] } },
                });
            }
        }

        case PRODUCT_SET_OPTION_UNIQUE_EXTRA_PLUS_FOR_A_GROUP_OPTION: {
            const index = state.composition.properties.findIndex((item) => {
                return (
                    item.optionGroupId === action.payload.optionGroupId &&
                    item.type === 'options'
                );
            });

            if (index !== -1) {
                const index1 = state.composition.properties[
                    index
                ].options.findIndex((item) => {
                    return (
                        item.optionId === action.payload.optionId &&
                        item.type === 'options'
                    );
                });

                if (index1 !== -1) {
                    if (action.payload.nb > 0) {
                        return update(state, {
                            composition: {
                                properties: {
                                    [index]: {
                                        options: {
                                            $splice: [[index1, 1]],
                                            $push: [action.payload],
                                        },
                                    },
                                },
                            },
                        });
                    } else {
                        return update(state, {
                            composition: {
                                properties: {
                                    [index]: {
                                        options: { $splice: [[index1, 1]] },
                                    },
                                },
                            },
                        });
                    }
                } else {
                    return update(state, {
                        composition: {
                            properties: {
                                [index]: {
                                    options: { $push: [action.payload] },
                                },
                            },
                        },
                    });
                }
            }

            return state;
        }

        /*  MULTIPLE EXTRA   */

        case PRODUCT_SET_OPTION_MULTIPLE_EXTRA: {
            const index = state.composition.properties.findIndex((item) => {
                return (
                    item.optionId === action.payload.optionId &&
                    item.type === 'options'
                );
            });

            if (index !== -1) {
                const values = [];

                if (action.payload.checked === true) {
                    values.push({
                        optionValueId: action.payload.values[0].optionValueId,
                        optionValueLabel:
                            action.payload.values[0].optionValueLabel,
                        cartPrice: action.payload.values[0].cartPrice,
                        taxRate: action.payload.values[0].taxRate,
                    });
                }

                // si le maximum est > 1, on rajoute les options actuelles
                if (action.payload.optionMaximum > 0) {
                    state.composition.properties[index].values.map((ov) => {
                        if (values.includes(ov.optionValueId) === false) {
                            if (
                                ov.optionValueId !==
                                action.payload.values[0].optionValueId
                            ) {
                                values.push({
                                    optionValueId: ov.optionValueId,
                                    optionValueLabel: ov.optionValueLabel,
                                    cartPrice: ov.cartPrice,
                                    taxRate: ov.taxRate,
                                });
                            }
                        }
                    });
                }

                return update(state, {
                    composition: {
                        properties: {
                            [index]: { values: { $set: values } },
                        },
                    },
                });
            } else {
                return update(state, {
                    composition: { properties: { $push: [action.payload] } },
                });
            }
        }

        case PRODUCT_SET_OPTION_MULTIPLE_EXTRA_FOR_A_GROUP_OPTION: {
            const index = state.composition.properties.findIndex((item) => {
                return (
                    item.optionGroupId === action.payload.optionGroupId &&
                    item.type === 'options'
                );
            });

            if (index !== -1) {
                const index1 = state.composition.properties[
                    index
                ].options.findIndex((item) => {
                    return (
                        item.optionId === action.payload.optionId &&
                        item.type === 'options'
                    );
                });

                const values = [];

                if (index1 !== -1) {
                    if (action.payload.checked === true) {
                        values.push({
                            optionValueId:
                                action.payload.values[0].optionValueId,
                            price: action.payload.values[0].price,
                        });
                    }

                    state.composition.properties[index].options[
                        index1
                    ].values.map((ov) => {
                        if (values.includes(ov.optionValueId) === false) {
                            if (
                                ov.optionValueId !==
                                action.payload.values[0].optionValueId
                            ) {
                                values.push({
                                    optionValueId: ov.optionValueId,
                                    price: ov.price,
                                });
                            }
                        }
                    });

                    return update(state, {
                        composition: {
                            properties: {
                                [index]: {
                                    options: {
                                        [index1]: { values: { $set: values } },
                                    },
                                },
                            },
                        },
                    });
                } else {
                    return update(state, {
                        composition: {
                            properties: {
                                [index]: {
                                    options: { $push: [action.payload] },
                                },
                            },
                        },
                    });
                }
            }
            return state;
        }

        /*  MULTIPLE EXTRA PLUS  */

        case PRODUCT_SET_OPTION_MULTIPLE_EXTRA_PLUS: {
            const index = state.composition.properties.findIndex((item) => {
                return (
                    item.optionId === action.payload.optionId &&
                    item.type === 'options'
                );
            });

            if (index !== -1) {
                const values = [];
                if (action.payload.values[0].nb > 0) {
                    values.push({
                        optionValueId: action.payload.values[0].optionValueId,
                        optionValueLabel:
                            action.payload.values[0].optionValueLabel,
                        cartPrice: action.payload.values[0].cartPrice,
                        taxRate: action.payload.values[0].taxRate,
                        nb: action.payload.values[0].nb,
                    });
                }

                state.composition.properties[index].values.map((ov) => {
                    if (values.includes(ov.optionValueId) === false) {
                        if (
                            ov.optionValueId !==
                            action.payload.values[0].optionValueId
                        ) {
                            values.push({
                                optionValueId: ov.optionValueId,
                                optionValueLabel: ov.optionValueLabel,
                                cartPrice: ov.cartPrice,
                                taxRate: ov.taxRate,
                                nb: ov.nb,
                            });
                        }
                    }
                });

                return update(state, {
                    composition: {
                        properties: {
                            [index]: { values: { $set: values } },
                        },
                    },
                });
            } else {
                return update(state, {
                    composition: { properties: { $push: [action.payload] } },
                });
            }
        }

        case PRODUCT_SET_OPTION_MULTIPLE_EXTRA_PLUS_FOR_A_GROUP_OPTION: {
            const index = state.composition.properties.findIndex((item) => {
                return (
                    item.optionGroupId === action.payload.optionGroupId &&
                    item.type === 'options'
                );
            });

            if (index !== -1) {
                const index1 = state.composition.properties[
                    index
                ].options.findIndex((item) => {
                    return (
                        item.optionId === action.payload.optionId &&
                        item.type === 'options'
                    );
                });

                if (index1 !== -1) {
                    const values = [];
                    if (action.payload.values[0].nb > 0) {
                        values.push({
                            optionValueId:
                                action.payload.values[0].optionValueId,
                            price: action.payload.values[0].price,
                            nb: action.payload.values[0].nb,
                        });
                    }

                    state.composition.properties[index].options[
                        index1
                    ].values.map((ov) => {
                        if (values.includes(ov.optionValueId) === false) {
                            if (
                                ov.optionValueId !==
                                action.payload.values[0].optionValueId
                            ) {
                                values.push({
                                    optionValueId: ov.optionValueId,
                                    price: ov.price,
                                    nb: ov.nb,
                                });
                            }
                        }
                    });
                    return update(state, {
                        composition: {
                            properties: {
                                [index]: {
                                    options: {
                                        [index1]: { values: { $set: values } },
                                    },
                                },
                            },
                        },
                    });
                } else {
                    return update(state, {
                        composition: {
                            properties: {
                                [index]: {
                                    options: { $push: [action.payload] },
                                },
                            },
                        },
                    });
                }
            }

            return state;
        }

        /*  PRODUCT_SET_OPTION_REPLACE  */

        case PRODUCT_SET_OPTION_REPLACE: {
            const index = state.composition.properties.findIndex((item) => {
                return (
                    item.uuid === action.payload.uuid && item.type === 'options'
                );
            });

            if (index !== -1) {
                return update(state, {
                    composition: {
                        properties: { $splice: [[index, 1]] },
                    },
                });
            } else {
                return update(state, {
                    composition: {
                        properties: { $push: [action.payload] },
                    },
                });
            }
        }

        /*  CART    */

        case CART_PRODUCT_ADD: {
            let match = false;

            for (let i = 0; i < state.cart.length; i++) {
                const res = isEqual(action.payload, state.cart[i]);

                if (res === true) {
                    match = i;
                    break;
                }
            }

            if (match !== false) {
                let quantity = state.cart[match].quantity + 1;
                return update(state, {
                    cart: {
                        products: {
                            [match]: { $merge: { quantity: quantity } },
                        },
                    },
                });
            } else {
                if (
                    action.payload.properties &&
                    action.payload.properties.length > 0
                ) {
                    MultiSort(action.payload.properties, {
                        position: 'ASC',
                    });
                }

                return update(state, {
                    cart: { products: { $push: [action.payload] } },
                });
            }
        }

        case CART_PRODUCT_REMOVE_BY_PRODUCT_ID: {
            //console.log('HHHHHHHH');

            const index = state.cart.products.findIndex(
                (item) => item.id === action.payload,
            );

            if (index !== -1) {
                return update(state, {
                    cart: { products: { $splice: [[index, 1]] } },
                });
            }

            return state;
        }

        case CART_PRODUCT_REMOVE: {
            return update(state, {
                cart: { products: { $splice: [[action.payload.index, 1]] } },
            });
        }

        case CART_PRODUCT_REMOVE_ONE: {
            let quantity =
                state.cart.products[action.payload.index].quantity - 1;
            return update(state, {
                cart: {
                    products: {
                        [action.payload.index]: {
                            $merge: { quantity: quantity },
                        },
                    },
                },
            });
        }

        case CART_PRODUCT_ADD_ONE: {
            let quantity =
                state.cart.products[action.payload.index].quantity + 1;

            return update(state, {
                cart: {
                    products: {
                        [action.payload.index]: {
                            $merge: { quantity: quantity },
                        },
                    },
                },
            });
        }

        case CART_PRODUCT_UPDATE_QUANTITY: {
            return update(state, {
                cart: {
                    products: {
                        [action.payload.index]: {
                            $merge: { quantity: action.payload.nb },
                        },
                    },
                },
            });
        }

        case RESET_CART: {
            return { ...state, cart: cart, cartFormula: [] };
        }

        case SET_SCHEDULE: {
            return update(state, {
                cart: { schedule: { $set: action.payload } },
            });
        }

        case SET_CART_USER_INFO: {
            return update(state, {
                cart: { user: { $set: action.payload } },
            });
        }

        case SET_ELIOR_MENU_TYPE: {
            console.log('SET_ELIOR_MENU_TYPE');
            return update(state, {
                menuType: { $set: 'takeaway' },
            });
        }

        case SET_CURRENCY: {
            return { ...state, currency: action.payload };
        }

        case SET_BROWSER_LANG: {
            const dd = parseLanguages(action.payload);

            let lang = 'fr';
            if (dd && dd[0] && dd[0].code) {
                if (['de', 'es', 'fr', 'en', 'it'].includes(dd[0].code)) {
                    lang = dd[0].code;
                }
            }

            return { ...state, lang: lang };
        }

        case ORDER_SENDING: {
            return { ...state, orderSending: action.payload };
        }

        case BOOKING_SENDING: {
            return { ...state, bookingSending: action.payload };
        }

        case REDUCED_REGIME: {
            return { ...state, reducedRegime: action.payload };
        }

        case CART_SET_COMMENT: {
            return update(state, {
                cart: { comment: { $set: action.payload } },
            });
        }

        case CART_SET_CUSTOMER_FIRSTNAME: {
            console.log('CART_SET_CUSTOMER_FIRSTNAME');
            console.log(action.payload);
            return update(state, {
                cart: {
                    customer_firstname: { $set: action.payload },
                },
            });
        }

        case CART_SET_NB_PEOPLE: {
            return update(state, {
                cart: { nbPeople: { $set: action.payload } },
            });
        }

        case SET_VENUE: {
            return update(state, {
                cart: { venue: { $set: action.payload } },
            });
        }

        case MENU_SET_INFO: {
            window.business = action.payload;
            return update(state, {
                business: { $set: action.payload },
                formulaBuilding: {
                    $set: action.payload.formulas
                        ? getFormulasUuid(action.payload.formulas)
                        : [],
                },
            });
        }

        /*  FORMULA */

        case FORMULA_HANDLE_REMOVAL_INGREDIENTS: {
            console.log('FORMULA_HANDLE_REMOVAL_INGREDIENTS');
            console.log(action.payload);

            const indexTest = state.composition.formulas.findIndex(
                (item) =>
                    item.formula_option_id === action.payload.formulaOptionId,
            );

            if (indexTest >= 0) {
                const index = state.composition?.formulas[
                    indexTest
                ]?.removalIngredients.findIndex((id) => {
                    return id === action.payload.ingredientId;
                });

                if (index !== -1) {
                    return update(state, {
                        composition: {
                            formulas: {
                                [indexTest]: {
                                    removalIngredients: {
                                        $splice: [[index, 1]],
                                    },
                                },
                            },
                        },
                    });
                } else {
                    return update(state, {
                        composition: {
                            formulas: {
                                [indexTest]: {
                                    removalIngredients: {
                                        $push: [action.payload.ingredientId],
                                    },
                                },
                            },
                        },
                    });
                }
            }
            return state;
        }

        case COMPOSITION_FORMULA_ADD_OPTION: {
            console.log('COMPOSITION_FORMULA_ADD_OPTION');
            console.log(action.payload);

            const indexTest = state.composition.formulas.findIndex(
                (item) =>
                    item.formula_option_id === action.payload.option.id &&
                    item.formula_option_value_id ===
                        action.payload.optionValue.id,
            );

            if (indexTest !== -1) {
                return state;
            }

            const index = state.composition.formulas.findIndex(
                (item) => item.formula_option_id === action.payload.option.id,
            );

            const payload = {
                price: action.payload.price,
                taxRate: action.payload.taxRate,
                formula_option_id: action.payload.option.id,
                formula_option_value_id: action.payload.optionValue.id,
                business_product_id: action.payload.product.business_product_id,
                productLabel: action.payload.product.name,
                quantity: 1,
                properties: action.payload.properties,
                removalIngredients: [],
            };

            if (index === -1) {
                return update(state, {
                    composition: {
                        formulas: { $push: [payload] },
                    },
                });
            } else {
                return update(state, {
                    composition: {
                        formulas: { $splice: [[index, 1]], $push: [payload] },
                    },
                });
            }

            return state;
        }

        case COMPOSITION_FORMULA_REMOVE_OPTION: {
            console.log('COMPOSITION_FORMULA_REMOVE_OPTION');
            console.log(action.payload);

            const index = state.composition.formulas.findIndex(
                (item) => item.formula_option_id === action.payload.id,
            );

            if (index !== -1) {
                return update(state, {
                    composition: {
                        formulas: { $splice: [[index, 1]] },
                    },
                });
            }

            return state;
        }

        case PRODUCT_FORMULA_SET_OPTION_UNIQUE_CHOICE: {
            console.log('PRODUCT_FORMULA_SET_OPTION_UNIQUE_CHOICE');
            console.log(action.payload);
            //console.log(state.composition);

            const index = state.composition.formulas.findIndex(
                (item) =>
                    item.formula_option_id === action.payload.formulaOption.id,
            );

            if (index !== -1) {
                const index2 = state.composition.formulas[
                    index
                ].properties.findIndex(
                    (item) => item.optionId === action.payload.optionId,
                );

                if (index2 !== -1) {
                    return update(state, {
                        composition: {
                            formulas: {
                                [index]: {
                                    properties: {
                                        $splice: [[index2, 1]],
                                        $push: [action.payload],
                                    },
                                },
                            },
                        },
                    });
                } else {
                    return update(state, {
                        composition: {
                            formulas: {
                                [index]: {
                                    properties: { $push: [action.payload] },
                                },
                            },
                        },
                    });
                }
            } else {
                const payload = {
                    formula_option_id: action.payload.formulaOption.id,
                    formula_option_value_id:
                        action.payload.formulaOptionValue.id,
                    business_product_id:
                        action.payload.product.business_product_id,
                    productLabel: action.payload.product.name,
                    quantity: 1,
                    properties: [action.payload],
                };
                return update(state, {
                    composition: {
                        formulas: { $push: [payload] },
                    },
                });
            }
        }

        case PRODUCT_FORMULA_SET_OPTION_UNIQUE_EXTRA: {
            //console.log('PRODUCT_FORMULA_SET_OPTION_UNIQUE_EXTRA');
            //console.log(action.payload);
            //console.log(state.composition);

            const index = state.composition.formulas.findIndex(
                (item) =>
                    item.formula_option_id === action.payload.formulaOption.id,
            );

            //console.log(index);

            if (index !== -1) {
                const index2 = state.composition.formulas[
                    index
                ].properties.findIndex(
                    (item) => item.optionId === action.payload.optionId,
                );

                //console.log(index2);

                if (index2 !== -1) {
                    return update(state, {
                        composition: {
                            formulas: {
                                [index]: {
                                    properties: { $splice: [[index2, 1]] },
                                },
                            },
                        },
                    });
                } else {
                    return update(state, {
                        composition: {
                            formulas: {
                                [index]: {
                                    properties: { $push: [action.payload] },
                                },
                            },
                        },
                    });
                }
            } else {
                const payload = {
                    formula_option_id: action.payload.formulaOption.id,
                    formula_option_value_id:
                        action.payload.formulaOptionValue.id,
                    business_product_id:
                        action.payload.product.business_product_id,
                    productLabel: action.payload.product.name,
                    quantity: 1,
                    properties: [action.payload],
                };
                return update(state, {
                    composition: {
                        formulas: { $push: [payload] },
                    },
                });
            }
        }

        case PRODUCT_FORMULA_SET_OPTION_UNIQUE_EXTRA_PLUS: {
            console.log('PRODUCT_FORMULA_SET_OPTION_UNIQUE_EXTRA_PLUS');
            console.log(action.payload);
            console.log(state.composition);

            const index = state.composition.formulas.findIndex(
                (item) =>
                    item.formula_option_id === action.payload.formulaOption.id,
            );

            if (index !== -1) {
                const index2 = state.composition.formulas[
                    index
                ].properties.findIndex(
                    (item) => item.optionId === action.payload.optionId,
                );

                if (index2 !== -1) {
                    if (action.payload.nb > 0) {
                        return update(state, {
                            composition: {
                                formulas: {
                                    [index]: {
                                        properties: {
                                            $splice: [[index2, 1]],
                                            $push: [action.payload],
                                        },
                                    },
                                },
                            },
                        });
                    } else {
                        return update(state, {
                            composition: {
                                formulas: {
                                    [index]: {
                                        properties: { $splice: [[index2, 1]] },
                                    },
                                },
                            },
                        });
                    }
                } else {
                    return update(state, {
                        composition: {
                            formulas: {
                                [index]: {
                                    properties: { $push: [action.payload] },
                                },
                            },
                        },
                    });
                }
            } else {
                const payload = {
                    formula_option_id: action.payload.formulaOption.id,
                    formula_option_value_id:
                        action.payload.formulaOptionValue.id,
                    business_product_id:
                        action.payload.product.business_product_id,
                    productLabel: action.payload.product.name,
                    quantity: 1,
                    properties: [action.payload],
                };
                return update(state, {
                    composition: {
                        formulas: { $push: [payload] },
                    },
                });
            }
        }

        case PRODUCT_FORMULA_SET_OPTION_MULTIPLE_EXTRA: {
            console.log('PRODUCT_FORMULA_SET_OPTION_MULTIPLE_EXTRA');
            console.log(action.payload);
            console.log(state.composition);

            const index = state.composition.formulas.findIndex(
                (item) =>
                    item.formula_option_id === action.payload.formulaOption.id,
            );

            if (index !== -1) {
                const index2 = state.composition.formulas[
                    index
                ].properties.findIndex(
                    (item) => item.optionId === action.payload.optionId,
                );

                if (index2 !== -1) {
                    const values = [];

                    if (action.payload.checked === true) {
                        values.push({
                            optionValueId:
                                action.payload.values[0].optionValueId,
                            optionValueLabel:
                                action.payload.values[0].optionValueLabel,
                            cartPrice: action.payload.values[0].cartPrice,
                            taxRate: action.payload.values[0].taxRate,
                        });
                    } else {
                        // on supprime l'option
                        if (
                            state.composition.formulas[index].properties[index2]
                                .values.length === 1
                        ) {
                            return update(state, {
                                composition: {
                                    formulas: { $splice: [[index, 1]] },
                                },
                            });
                        }
                    }

                    // si le maximum est > 1, on rajoute les options actuelles
                    if (action.payload.optionMaximum > 0) {
                        state.composition.formulas[index].properties[
                            index2
                        ].values.map((ov) => {
                            if (values.includes(ov.optionValueId) === false) {
                                if (
                                    ov.optionValueId !==
                                    action.payload.values[0].optionValueId
                                ) {
                                    values.push({
                                        optionValueId: ov.optionValueId,
                                        optionValueLabel: ov.optionValueLabel,
                                        cartPrice: ov.cartPrice,
                                        taxRate: ov.taxRate,
                                    });
                                }
                            }
                        });
                    }

                    return update(state, {
                        composition: {
                            formulas: {
                                [index]: {
                                    properties: {
                                        [index2]: { values: { $set: values } },
                                    },
                                },
                            },
                        },
                    });
                } else {
                    console.log('BBBBBBB');
                    return update(state, {
                        composition: {
                            formulas: {
                                [index]: {
                                    properties: { $push: [action.payload] },
                                },
                            },
                        },
                    });
                }
            } else {
                const payload = {
                    formula_option_id: action.payload.formulaOption.id,
                    formula_option_value_id:
                        action.payload.formulaOptionValue.id,
                    business_product_id:
                        action.payload.product.business_product_id,
                    productLabel: action.payload.product.name,
                    quantity: 1,
                    properties: [action.payload],
                };
                return update(state, {
                    composition: {
                        formulas: { $push: [payload] },
                    },
                });
            }
        }

        case PRODUCT_FORMULA_SET_OPTION_MULTIPLE_EXTRA_PLUS: {
            console.log('PRODUCT_FORMULA_SET_OPTION_MULTIPLE_EXTRA_PLUS');
            console.log(action.payload);
            console.log(state.composition);

            const index = state.composition.formulas.findIndex(
                (item) =>
                    item.formula_option_id === action.payload.formulaOption.id,
            );
            console.log(index);

            if (index !== -1) {
                const index2 = state.composition.formulas[
                    index
                ].properties.findIndex(
                    (item) => item.optionId === action.payload.optionId,
                );

                if (index2 !== -1) {
                    const values = [];
                    if (action.payload.values[0].nb > 0) {
                        values.push({
                            optionValueId:
                                action.payload.values[0].optionValueId,
                            optionValueLabel:
                                action.payload.values[0].optionValueLabel,
                            cartPrice: action.payload.values[0].cartPrice,
                            taxRate: action.payload.values[0].taxRate,
                            nb: action.payload.values[0].nb,
                        });
                    }

                    state.composition.formulas[index].properties[
                        index2
                    ].values.map((ov) => {
                        if (values.includes(ov.optionValueId) === false) {
                            if (
                                ov.optionValueId !==
                                action.payload.values[0].optionValueId
                            ) {
                                values.push({
                                    optionValueId: ov.optionValueId,
                                    optionValueLabel: ov.optionValueLabel,
                                    cartPrice: ov.cartPrice,
                                    taxRate: ov.taxRate,
                                    nb: ov.nb,
                                });
                            }
                        }
                    });

                    return update(state, {
                        composition: {
                            formulas: {
                                [index]: {
                                    properties: {
                                        [index2]: { values: { $set: values } },
                                    },
                                },
                            },
                        },
                    });
                } else {
                    return update(state, {
                        composition: {
                            formulas: {
                                [index]: {
                                    properties: { $push: [action.payload] },
                                },
                            },
                        },
                    });
                }
            } else {
                const payload = {
                    formula_option_id: action.payload.formulaOption.id,
                    formula_option_value_id:
                        action.payload.formulaOptionValue.id,
                    business_product_id:
                        action.payload.product.business_product_id,
                    productLabel: action.payload.product.name,
                    quantity: 1,
                    properties: [action.payload],
                };
                return update(state, {
                    composition: {
                        formulas: { $push: [payload] },
                    },
                });
            }

            return state;
        }

        case SET_SELECTED_MENU: {
            return { ...state, selectedMenu: action.payload };
        }

        case SET_MENU_TYPE: {
            if (action.payload !== state.menuType) {
                return update(state, {
                    menuType: { $set: action.payload },
                    cart: { $set: cart },
                });
            }

            return update(state, {
                menuType: { $set: action.payload },
            });
        }

        default:
            return state;
    }
}
